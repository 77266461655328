@import url(https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  box-sizing: border-box;
}

body {
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  line-height: 1.7;
  padding:0;
  margin: 0;
}

.word {
  font-size: 5rem;
  font-weight: bold;
}

button {
  font-size: 2rem;
  margin: 0.75em;
  padding: 0.3em 0.5em;
  font-weight: bold;
  background-color: #c8f7f5;
  border: none;
  border-radius: 0.2em;
}


.centered {
  margin: 0 auto;
  text-align: center;
}

button.selected {
  background: #61fbf6;
}

footer {
  text-align: center;
  background: white;
  padding: 1em;
  width: 100%;
  position: absolute;
  z-index:10;
  bottom: 0;
}

footer p {
  font-size: 0.8rem;
  color: #888888;
}

.logo-text {
  color: #ED9F18;
}

.btn-primary {
  background: lightskyblue;
  color: white;
  border-radius: 0.5em;
}

.btn-primary:hover {
  background: skyblue;
}

.maingame {
  display: flex;
  flex-direction: row;
  z-index:1;
  height: 100vh;
}

.wordcontainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.menucontainer {
  padding: 1em;
  display: flex;
  flex-direction: column;
  justify-content: center;

  box-shadow: #999 2rem 0 2rem 2rem;
}

/*************/
/* Onboaring */
/*************/
.intro {
  max-width: 50rem;
  margin: 0 auto;
  padding: 0.5em;
}

ol.onboarding li{
  font-weight: bold;
  font-size: 2rem;
}

.onboarding-subscript {
  font-weight: normal;
  font-size: 0.5em;
  
  margin: 0.5em 2em; 
}

@media only screen and (max-width: 600px) {
  .maingame {
    flex-direction: column-reverse;
    justify-content: flex-end;
  }

  .menucontainer {
    box-shadow: #aaa 0 -2rem 2rem 2rem;
  }

}
